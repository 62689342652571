import { PRODUCTS as ActionTypes } from '../actionTypes';
import { productInit } from '../../services/productApi';

const initialState = {
	productViewCategory: 'Devices',
	product: productInit,
	productsFull: [],
	devicesLookup: [],
	ratePlansLookup: [],
	accessoriesLookup: [],
	singleProduct: {
		bannerMessage: '',
		battery: '',
		carrier: '',
		description: [],
		deviceBannerMessage: '',
		display: '',
		durability: '',
		eppOptions: [],
		financeTerms: [],
		id: '',
		image_1: '',
		image_2: '',
		image_3: '',
		image_4: '',
		image_5: '',
		image_6: '',
		images: [],
		included: [],
		isDSPItem: true,
		manufacturer: "",
		memory: '',
		processor: '',
		productCategory: "",
		productCode: "",
		productName: "",
		promoDiscount: '',
		showBanner: false,
		showDeviceBanner: false,
		unitPrice: 0,
		usb: '',
		warranty: ''
	}
};


export const productReducer = (state = initialState, action) => {

	switch (action.type) {

		case ActionTypes.SET_PRODUCT_CAT:
			return {
				...state,
				productViewCategory: action.payload
			};



		case ActionTypes.SET_SINGLE_PRODUCT:
			return {
				...state,
				product: action.payload
			};

		case ActionTypes.SET_PRODUCTS:
			return {
				...state,
				productsFull: action.payload
			};

		case ActionTypes.SET_DROPDOWN_LISTS:
			return {
				...state,
				devicesLookup: action.payload.devices,
				ratePlansLookup: action.payload.rates,
				accessoriesLookup: action.payload.accessories
			};

		default:
			return state;
	};
};



