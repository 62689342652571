import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRootAuth = ENDPOINTS.API + '/auth';
const apiRootUser = ENDPOINTS.API + '/user';

const userInit = {
	"id": "",
	"email": "",
	"firstLast": "",
	"firstName": "",
	"lastFirst": "",
	"lastName": "",
	"phoneFormatted": "",
	"phone10Digits": "",
	"statusId": 1,
	"status": "",
	"password": "",
	"confirmPassword": "",
	"dateCreated": "",
	"modifiedBy": "",
	"dateModified": "",
	"code": ""
};

export { userInit };

export const register = (data) => {

	let url = `${apiRootAuth}/AddUser`;

	let request = {
		email: data.email,
		password: data.password,
		firstName: data.firstName,
		lastName: data.lastName,
		code: data.code
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};

	return handleFetch(url, options, false);
};


export const sendResetToken = (data) => {

	let url = `${apiRootAuth}/ResetPassword`;

	let request = {
		"email": data,
		"callBackUrl": ENDPOINTS.CALL_BACK_URL
	};


	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, false);
};


export const login = (data) => {

	let url = `${apiRootAuth}/UserLogin`;

	let request = {
		email: data.username,
		password: data.password
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, false);
};


export const getUserProfile = (userGuid) => {

	let url = `${apiRootUser}/GetSingle/${userGuid}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};



// export const getAllByCustomerId = (customerId) => {
// 	let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

// 	const options = {
// 		method: API_METHOD.POST
// 	};
// 	return handleFetch(url, options, true);
// };

// export const getSingle = (id) => {

// 	let url = `${apiRoot}/GetSingle/${id}`;

// 	const options = {
// 		method: API_METHOD.GET
// 	};

// 	try {
// 		return handleFetch(url, options, true);
// 	} catch(e) {
// 		debugger;
// 	}
// };
// export const update = (data) => {
// 	let url = `${apiRoot}/Update`;

// 	const options = {
// 		method: API_METHOD.POST,
// 		body: JSON.stringify(data)
// 	};
// 	return handleFetch(url, options, true);
// };

// export const insert = (data) => {
// 	let url = `${apiRoot}/Insert`;

// 	const options = {
// 		method: API_METHOD.POST,
// 		body: JSON.stringify(data)
// 	};
// 	return handleFetch(url, options, true);
// };