import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';


import PrivateRoute from './PrivateRoute';
import { CUSTOMER_ROUTES } from './app_nav_consts';
import ResetPassword from '../pages/landing/ResetPassword';
// lazy load all the views



// auth
//const Login = React.lazy(() => import('../pages/landing/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));

const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));


// dashboard

// apps
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
// - other
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

const Landing = React.lazy(() => import('../pages/landing'));
const DspOrders = React.lazy(() => import('../pages/landing/dsp-orders/'));
const DeviceDetails = React.lazy(() => import('../pages/landing/dsp-orders/DeviceDetails'));
const OrderDetails = React.lazy(() => import('../pages/landing/dsp-orders/OrderDetails'));
const CartDetails = React.lazy(() => import('../pages/landing/dsp-orders/CartDetails'));
const PreCheckout = React.lazy(() => import('../pages/landing/dsp-orders/PreCheckout'));
const Login = React.lazy(() => import('../pages/landing/Login'));
const Register = React.lazy(() => import('../pages/landing/Register'));




// root routes
const rootRoute = {
    path: '/',
    exact: true,
    //component: () => <Redirect to="/home" />,
    component: Login, // load login screen on launch

    route: Route,
};




const otherPublicRoutes = [
    {
        path: '/home',
        name: 'landing',
        component: Landing,
        route: Route,
    },
    // {
    //     path: '/landing',
    //     name: 'landing',
    //     component: Landing,
    //     route: Route,
    // },
    {
        path: '/forgot-password',
        name: 'reset',
        component: ResetPassword,
        route: Route,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        route: Route,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        route: Route,
    },
    {
        path: '/dsporders',
        name: 'dsporders',
        component: DspOrders,
        route: Route,
    },
    {
        path: '/device-details',
        name: 'deviceDetails',
        component: DeviceDetails,
        route: Route,
    },
    {
        path: '/order-details',
        name: 'orderDetails',
        component: PreCheckout,
        route: Route,
    },
    {
        path: '/cart-details',
        name: 'cartDetails',
        component: CartDetails,
        route: Route,
    },

    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: ErrorPageNotFound,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: ServerError,
        route: Route,
    },
    {
        path: '/account/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '*',
        name: 'Test',
        component: Landing,
        route: Route,
    },
];



// auth
// const authRoutes = [
//     {
//         path: '/account/login',
//         name: 'Login',
//         component: Login,
//         route: Route,
//     },
//     {
//         path: '/account/logout',
//         name: 'Logout',
//         component: Logout,
//         route: Route,
//     },
//     {
//         path: '/account/register',
//         name: 'Register',
//         component: Register,
//         route: Route,
//     },
//     {
//         path: '/account/forget-password',
//         name: 'Forget Password',
//         component: ForgetPassword,
//         route: Route,
//     },
//     {
//         path: '/account/lock-screen',
//         name: 'Lock Screen',
//         component: LockScreen,
//         route: Route,
//     },
//     {
//         path: '/account/login2',
//         name: 'Login2',
//         component: Login2,
//         route: Route,
//     },
//     {
//         path: '/account/logout2',
//         name: 'Logout2',
//         component: Logout2,
//         route: Route,
//     },
//     {
//         path: '/account/register2',
//         name: 'Register2',
//         component: Register2,
//         route: Route,
//     },
//     {
//         path: '/account/confirm2',
//         name: 'Confirm2',
//         component: Confirm2,
//         route: Route,
//     },
//     {
//         path: '/account/forget-password2',
//         name: 'Forget Password2',
//         component: ForgetPassword2,
//         route: Route,
//     },
//     {
//         path: '/account/lock-screen2',
//         name: 'Lock Screen2',
//         component: LockScreen2,
//         route: Route,
//     },
// ];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
//const authProtectedRoutes = [...appRoutes];
const publicRoutes = [rootRoute, ...otherPublicRoutes];

//const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, publicProtectedFlattenRoutes };
