import { PRODUCTS } from '../actionTypes';
import * as productApi from '../../services/productApi';


export function getProducts(category) {
    // get all active products and set all into redux along with the lookup lists
    return async (dispatch) => {


        let response = {};

        response = await productApi.getAll(category);

        // get offline                
        //if(response.data.hasError === false) {

        dispatch(
            {
                type: PRODUCTS.SET_PRODUCTS,
                payload: response.data
            }
        );


        // map
        let deviceDD = response.data.filter(r => r.productCategory === "Hardware").map((item) => {
            return {
                value: item.id,
                label: item.productName
            };
        });

        let ratesDD = response.data.filter(r => r.productCategory === "Rate Plan").map((item) => {
            return {
                value: item.id,
                label: item.productName
            };
        });

        let accessoriesDD = response.data.filter(r => r.productCategory === "Accessory").map((item) => {
            return {
                value: item.id,
                label: item.productName
            };
        });
        let myLists = {
            devices: deviceDD,
            rates: ratesDD,
            accessories: accessoriesDD
        };

        dispatch(
            {
                type: PRODUCTS.SET_DROPDOWN_LISTS,
                payload: myLists
            }
        );

        //} else {
        // we got a bad user, logout
        //   return false;
        // }
    };
}


export function setProductCategory(item) {

    return async (dispatch) => {

        let payload = item;

        dispatch(
            {
                type: PRODUCTS.SET_PRODUCT_CAT,
                payload: payload
            }
        );
    };
}


export function setSingleProduct(item) {
    // pass the item to set into redux
    return async (dispatch) => {

        let payload = { ...item };
        sessionStorage.setItem('selected-device', JSON.stringify(payload));

        dispatch(
            {
                type: PRODUCTS.SET_SINGLE_PRODUCT,
                payload: payload
            }
        );
    };
}

export function setSingleProductFromStorage() {
    // unlike setting the item we pass, we are getting the item that's in storage and adding it back into redux
    return async (dispatch) => {

        let payload = {};
        let sessionDevice = sessionStorage.getItem('selected-device');
        if (sessionDevice) {

            payload = JSON.parse(sessionDevice);
        }

        dispatch(
            {
                type: PRODUCTS.SET_SINGLE_PRODUCT,
                payload: payload
            }
        );

        return payload;
    };
}

// export function getSingleProduct(item) {
//     // get all active products and set all into redux along with the lookup lists
//     return async (dispatch) => {

//         let payload = {};
//         let sessionDevice = sessionStorage.getItem('selected-device');
//         if (sessionDevice) {
//             payload = JSON.parse(sessionDevice);
//         }

//         dispatch(
//             {
//                 type: PRODUCTS.GET_SINGLE_PRODUCT,
//                 payload: payload
//             }
//         );
//     };
// }