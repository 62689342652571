// used to prevent misspellings and scale changes to app wide history.push('xxxxx')
export const APP_ROUTES = {
	LOGOUT: '/account/logout',
	LOGIN: '/account/login'
};


export const CUSTOMER_ROUTES = {
	ROOT: '/customers',
	DASHBOARD: '/customers/customer-dashboard',
	DETAILS: '/customers/customer-details',
	NOTES: '/customers/customer-notes',
	AGENTS: '/customers/customer-agents',
	BUILDINGS: '/customers/customer-buildings',
	DEVICES: '/customers/customer-devices',
	NEW: '/customers/customer-new',
};

export const AGENT_ROUTES = {
	ROOT: '/agents',
	DASHBOARD: '/agents/dashboard',
	DETAILS: '/agents/agent-details',
	// NOTES: '/customers/customer-notes',
	// AGENTS: '/customers/customer-agents',
};